import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { $api } from 'shared/api/api';
import { JsonToFormData } from 'shared/lib/JsonToFormData';
import { BlockLoader } from 'shared/ui/BlockLoader';
import StatisticTable from 'shared/ui/Statistic/Table';
import { Title } from 'shared/ui/Title';
import { Wrapper } from 'shared/ui/Wrapper';
import cls from 'shared/ui/Statistic/Statistic.module.scss';
import { Button, ButtonTheme } from 'shared/ui/Button';
import ExportB from 'shared/assets/icons/exportB.svg';
import ReactPaginate from 'react-paginate';
import { Modal } from 'shared/ui/Modal';
import { Portal } from 'shared/ui/Portal';
import { TestPassingResultsWidget } from 'widgets/TestPassingResultsWidget';
var COLUMNS = {
    pos: '№',
    step: 'Название',
    user: 'Пользователь',
    // question: 'Вопрос',
    // answer: 'Ответ',
    publish: 'Опубликован',
    score: 'Баллы',
    time: 'Время',
    finished: 'Пройден',
    view: 'Просмотр',
};
export default function LessonTestsPage() {
    var _a;
    var lessonId = useParams().lessonId;
    var _b = useState(0), page = _b[0], setPage = _b[1];
    var _c = useState(null), tests = _c[0], setTests = _c[1];
    var _d = useState(), testPassingId = _d[0], setTestPassingId = _d[1];
    useEffect(function () {
        $api.post('/ext-course/frontend/manage/statistic/tests', { lessonId: lessonId, page: page, limit: 100 })
            .then(function (d) { return setTests(d.data); });
    }, [page]);
    var handlePageClick = function (value) {
        setPage(value.selected);
    };
    return (_jsxs(Wrapper, { className: cls.wrapper, children: [_jsx(Title, { children: "\u0421\u0442\u0430\u0442\u0438\u0441\u0442\u0438\u043A\u0430 \u043F\u0440\u043E\u0445\u043E\u0436\u0434\u0435\u043D\u0438\u044F \u0442\u0435\u0441\u0442\u0430" }), _jsx(Button, { className: cls.button, theme: ButtonTheme.PRIMARY, onClick: function () {
                    var formData = new FormData();
                    JsonToFormData(formData, {
                        exportType: 'Xlsx',
                        exportull_w0: 1,
                        export_columns: '["0","1","2","3"]',
                        column_selector_enabled: 1,
                    });
                    $api.post('/ext-course/frontend/manage/statistic/tests?export=true', { lessonId: lessonId }).then(function (response) {
                        if (response.status === 200) {
                            var blob = new Blob(["\uFEFF".concat(response.data.export)], { type: 'text/csv;charset=utf-8' });
                            console.log(URL.createObjectURL(blob));
                            var url = window.URL.createObjectURL(blob);
                            var downloadLink = document.createElement('a');
                            downloadLink.href = url;
                            downloadLink.download = 'exposrt.csv';
                            document.body.appendChild(downloadLink);
                            downloadLink.click();
                            document.body.removeChild(downloadLink);
                            window.URL.revokeObjectURL(url);
                            return;
                        }
                        throw new Error('Failed to fetch statistic');
                    });
                }, icon: _jsx(ExportB, {}) }), /* mail == null || */ tests == null ? _jsx(BlockLoader, {})
                : (_jsxs(_Fragment, { children: [_jsx(StatisticTable, { columns: COLUMNS, children: (_a = tests.tests) === null || _a === void 0 ? void 0 : _a.map(function (test, index) { return (_jsx("tbody", { children: _jsxs("tr", { children: [_jsx("td", { children: index + 1 }), _jsx("td", { children: test.name }), _jsx("td", { children: test.user }), _jsx("td", { children: test.publish == 1 ? 'Да' : 'Нет' }), _jsx("td", { children: "0" }), _jsx("td", { children: "0" }), _jsx("td", { children: "\u0414\u0430" }), _jsx("td", { children: _jsx("p", { onClick: function () { return setTestPassingId(test.megatest_id); }, className: cls.view, children: "\u041F\u043E\u0441\u043C\u043E\u0442\u0440\u0435\u0442\u044C" }) })] }) }, index)); }) }), _jsx(ReactPaginate, { breakLabel: "...", nextLabel: ">", onPageChange: handlePageClick, pageRangeDisplayed: 2, pageCount: (tests.max - (tests.max % 100)) / 100 + (tests.max % 100 ? 1 : 0), previousLabel: "<", renderOnZeroPageCount: null, containerClassName: "pagination", pageClassName: "pagination__item", pageLinkClassName: "pagination__link", previousClassName: "pagination__item", previousLinkClassName: "pagination__link", nextClassName: "pagination__item", nextLinkClassName: "pagination__link" }), _jsx(Portal, { children: _jsx(Modal, { classNameContent: cls.modal, isOpen: !!testPassingId, onClose: function () { return setTestPassingId(undefined); }, children: testPassingId ? _jsx(TestPassingResultsWidget, { testPassingId: testPassingId }) : null }) })] }))] }));
}
